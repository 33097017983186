document.addEventListener("executeAdForm", function () {
    
	console.debug("INFO adform-include.js loaded");

	// CONSTANTS
    var ADFORM_CUSTOMER_ID = "47501";
    var COOKIE_NAME = "affiliatedata";
    var COOKIE_REGEX = /^ewbs:([0-9]{6}),apid:([a-zA-Z0-9_-]*)$/;

    // GET PAGE ATTRIBUTE FROM META-TAG
    var pageName = VWFSUtils.getMetaContent('adform-page-name');

	// GET COOKIE-CONSENT FOR ADFORM
	var cookieConsentAccepted = false;
	try {
		cookieConsentAccepted = JSON.parse(VWFSUtils.getCookie('CookieControl')).optionalCookies.adform == "accepted";
	} catch(error) {
		console.debug("ERROR: adform-include.js: Something went wrong while trying to get CookieControl status for 'adform'.");
	}
	
	// CHECK IF NAME EXISTS AND ADFORM IS ACCEPTED
	if(cookieConsentAccepted) {
	    if (pageName) {
		
			// CREATE ADFORM IFRAME
	        var div = document.createElement('div');
	        div.innerHTML = "<iframe id='adform' src='" + generateAdformUrl(pageName) + "' width='1' height='1' style='display: none;'></iframe>";
	        document.body.appendChild(div.firstChild);
	
	    } else {
	        console.debug("ERROR: adform-include.js: PageName is null.");
	    }

	} else {
		console.debug("DEBUG: adform-include.js: CookieControl for 'adform' not accepted jet.");
	}

	/**
	 * GENERATE ADFORM URL
	 */
    function generateAdformUrl(pageName) {
	
        var pageNameParam = "ADFPageName=" + encodeURIComponent(window.location.host + "|" + pageName);
        var trackpoint = /*window.location.host.indexOf("localhost") === 0 ? window.location.host + "/1x1.png" : */ "server.adform.net/Serving/TrackPoint/";
        
		return "//" + trackpoint + "?pm=" + encodeURIComponent(ADFORM_CUSTOMER_ID) + 
			"&" + pageNameParam + "&ADFdivider=" + encodeURIComponent("|")
            + "&ADFtpmode=4&itm=" + encodeURIComponent(JSON.stringify(generateAdditionalData()));

    }

	/**
	 * GENERATE ADDITIONAL DATA
	 */
    function generateAdditionalData() {
	
        var cookie = getAffiliateCookie();
        var dmksId = VWFSUtils.getURLParameter('dmksId');

        return {
            "sv2": (cookie.ewbs ? cookie.ewbs : undefined),
            "sv3": dmksId,
            "sv4": (cookie.apid ? cookie.apid : undefined)
        };

    }

	/**
	 * GET AFFILIATE COOKIE
	 */
    function getAffiliateCookie() {
	
        var value = VWFSUtils.getCookie(COOKIE_NAME);
        var match = COOKIE_REGEX.exec(value);

        return {
            ewbs: (match && match.length >= 2 && match[1] ? match[1] : null),
            apid: (match && match.length >= 3 && match[2] ? match[2] : null)
        };

    }

});
